import { APP_CONFIG } from '../../../appConfig';
import { Service } from '../utils/types';

export const UpdateService = async (
    service: Service,
    token: string
): Promise<void> => {
    await fetch(`${APP_CONFIG.baseApiUrl}/offering/${service.offeringId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({
            title: service.title,
            description: service.description,
            maxNumberOfPeople: service.maxNumberOfGuest,
            minNumberOfPeople: service.minNumberOfGuest,
            priceTo: service.maxPrice,
            priceFrom: service.minPrice,
            entityIds: service.entityIds,
            locations: service.locations,
            hours: service.hours,
        }),
    });
};
