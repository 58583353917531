import { ImageDescriptor } from '../../../store/models/profile/profile';
import { APP_CONFIG } from '../../../appConfig';
import { mapImage } from '../utils/map';

export const AddImage = async (
    offeringId: string,
    image: File,
    token: string
): Promise<ImageDescriptor> => {
    const data = new FormData();
    data.append('file', image);

    const response = await fetch(
        `${APP_CONFIG.baseApiUrl}/offering/image/${offeringId}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'POST',
            body: data,
        }
    );
    return mapImage(await response.json());
};
