import { action, Action, thunk, Thunk } from 'easy-peasy';
import { getEntities } from '../../../components/service/remote-data/get-entites';
import { Entity } from '../../../infrastructure/types/entities';
import {
    GetMuncipials,
    GetAllSuppliers,
    GetEventCategories,
} from '../../../services/myhattClient';
import { Category } from '../events/constants';
import { Location, Supplier } from './interfaces';

export interface ExternalData {
    locations: Location[];

    suppliers: Supplier[];

    eventCategories: Category[];

    entities: Entity[];

    // actions
    setLocations: Action<ExternalData, Location[]>;
    setSuppliers: Action<ExternalData, Supplier[]>;
    setEventCatgories: Action<ExternalData, Category[]>;
    setEntities: Action<ExternalData, Entity[]>;
    // Thunks
    getLocations: Thunk<ExternalData, undefined>;
    getSuppliers: Thunk<ExternalData, undefined>;

    getEventCategories: Thunk<ExternalData, undefined>;

    loadEntities: Thunk<ExternalData>;
}

const externalData: ExternalData = {
    locations: [],
    suppliers: [],
    eventCategories: [],
    entities: [],

    setEntities: action((state, payload) => {
        state.entities = payload;
    }),
    setLocations: action((state, payload) => {
        state.locations = payload;
    }),

    setSuppliers: action((state, payload) => {
        state.suppliers = payload;
    }),

    setEventCatgories: action((state, payload) => {
        state.eventCategories = payload;
    }),

    getLocations: thunk(async (actions) => {
        try {
            const res = await GetMuncipials();

            actions.setLocations(
                res.map((x) => {
                    return { value: x.id, label: x.name } as Location;
                })
            );
        } catch (error) {
            // console.log(error);
        }
    }),
    getSuppliers: thunk(async (actions) => {
        try {
            const res = await GetAllSuppliers();

            actions.setSuppliers(res);
        } catch (error) {
            // TODO: Error handling
        }
    }),
    getEventCategories: thunk(async (actions) => {
        try {
            const res = await GetEventCategories();

            actions.setEventCatgories(res);
        } catch (error) {
            // TODO: Error handling
        }
    }),
    loadEntities: thunk(async (actions) => {
        const res = await getEntities();
        actions.setEntities(res);
    }),
};

export default externalData;
