import { APP_CONFIG } from '../../../appConfig';
import { SupplierInfo } from '../../../store/models/profile/profile';

export const GetProfile = async (
    token: string
): Promise<SupplierInfo | null> => {
    const response = await fetch(`${APP_CONFIG.baseApiUrl}/supplier`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    if (response.ok) return response.json();

    if (response.status === 404) {
        return null;
    }

    const err = new Error('Not 2xx repsonse');
    throw err;
};
