import { APP_CONFIG } from '../../../appConfig';
import { mapToEntities } from '../../../infrastructure/remote-data/entities/map';
import { Entity } from '../../../infrastructure/types/entities';

export const getEntities = async (): Promise<Entity[]> => {
    const response = await fetch(`${APP_CONFIG.baseApiUrl}/entity`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await response.json();

    return mapToEntities(data);
};
