import { APP_CONFIG } from '../../../appConfig';

export const DeleteProfile = async (token: string): Promise<void> => {
    await fetch(`${APP_CONFIG.baseApiUrl}/profile`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
    });
};
