/* eslint-disable @typescript-eslint/no-explicit-any */
import { setPropValue } from '../../infrastructure/utils/set-prop-value';
import { Area } from './types';

const mapToArea = (area: any): Area =>
    ({
        ...setPropValue('id', area, 'id'),
        ...setPropValue('name', area, 'name'),

        selected: false,
    } as Area);

export const mapToAreas = (areas: any[]): Area[] => {
    return areas.map((area: any) => mapToArea(area));
};
