import { User } from '@auth0/auth0-react';
import {
    action,
    Action,
    actionOn,
    ActionOn,
    computed,
    Computed,
    thunk,
    Thunk,
    thunkOn,
    ThunkOn,
} from 'easy-peasy';
import { debounce } from 'lodash';
import { StoreModel } from '..';
import {
    DeleteCompanyImage,
    SaveSupplier,
    UploadImage,
    UploadLogo,
} from '../../../components/profile/remote-data';
import { DeleteProfile } from '../../../components/profile/remote-data/delete-profile';
import { GetProfile } from '../../../components/profile/remote-data/get-profile';
import { CreateService } from '../../../components/service/remote-data/create-service';
import { DeleteService } from '../../../components/service/remote-data/delete-service';
import { LoadServices } from '../../../components/service/remote-data/get-services';
import { Service } from '../../../components/service/utils/types';
import { GetCompanyInfoByOrgnumber } from '../../../services/myhattClient';
import editServiceModel, { EditServiceModel } from '../myServices/services';

import { Severity } from '../notification/notification';

export interface ImageDescriptor {
    id: string;
    url: string;
    description: string;
    changed: boolean;
}

export interface Address {
    addresses: string[];
    zipCode: string;
    postalName: string;
}

const addressModel: Address = {
    addresses: [],
    postalName: '',
    zipCode: '',
};
export interface SupplierInfo {
    id?: string;
    bookingMailAddress?: string;
    contactPersonName?: string;
    entityIds: string[];
    images: ImageDescriptor[];
    billingSameAsVisiting: boolean;
    invoiceAddress: Address;
    legalName?: string;
    logoUrl?: string;
    name?: string;
    orgNumber?: number;
    phoneNumber?: number;
    contactPersonPhone?: number;
    termsAccepted: boolean;
    visitAddress: Address;
    websiteUrl?: string;
}
export interface ExternalCompanyInfo {
    orgNumber: string;
    legalName: string;
    visitAddress: Address;
}
interface LoadingError {
    severity: Severity;
    title: string;
    message: string;
}
export interface UploadImagePayload {
    file: File;
}

interface UserPayload {
    token: string | undefined;
    user: User | undefined;
}
export interface ProfileModel {
    user?: User;
    supplierInfo: SupplierInfo;
    isLoading: boolean;
    loadingError?: LoadingError;
    editServiceModel: EditServiceModel;
    services: Service[];
    token: string;
    viewService?: Service;

    isProfileComplete: Computed<ProfileModel, boolean>;

    setViewService: Action<ProfileModel, Service | undefined>;
    setIsLoading: Action<ProfileModel, boolean>;
    setLoadingError: Action<ProfileModel, LoadingError>;
    setUser: Action<ProfileModel, UserPayload>;
    addService: Action<ProfileModel, Service>;
    removeService: Action<ProfileModel, string>;
    setSupplier: Action<ProfileModel, SupplierInfo>;
    setFromExternalData: Action<ProfileModel, ExternalCompanyInfo>;
    setOrgNumber: Action<ProfileModel, number>;
    setIsAccepted: Action<ProfileModel, boolean>;
    setSupplierId: Action<ProfileModel, string>;
    setName: Action<ProfileModel, string>;
    setCompanyLogo: Action<ProfileModel, string>;
    setPhoneNumber: Action<ProfileModel, number>;
    setBookingMailAddress: Action<ProfileModel, string>;
    setContactPerson: Action<ProfileModel, string>;
    setWebsite: Action<ProfileModel, string>;
    setContactPhone: Action<ProfileModel, number>;
    setVisitingAddresses: Action<ProfileModel, string>;
    setVisitingZipCode: Action<ProfileModel, string>;
    setVisitingPostalName: Action<ProfileModel, string>;
    setBillingSameAsVisiting: Action<ProfileModel, boolean>;
    setBillingAddresses: Action<ProfileModel, string>;
    setBillingZipCode: Action<ProfileModel, string>;
    setBillingPostalName: Action<ProfileModel, string>;
    addCompanyImage: Action<ProfileModel, ImageDescriptor>;
    removeCompanyImage: Action<ProfileModel, ImageDescriptor>;

    getSupplier: Thunk<ProfileModel, undefined>;
    createNewSupplier: Thunk<ProfileModel, undefined>;
    updateSupplier: Thunk<ProfileModel, undefined>;
    uploadLogo: Thunk<ProfileModel, UploadImagePayload>;
    uploadImage: Thunk<ProfileModel, UploadImagePayload>;
    removeImage: Thunk<ProfileModel, ImageDescriptor>;
    createNewService: Thunk<ProfileModel, undefined>;
    deleteProfile: Thunk<ProfileModel, undefined>;

    onEditServiceHasChanges: ActionOn<ProfileModel, StoreModel>;

    onOrgNumberChanged: ThunkOn<ProfileModel>;
    onProfileDataChanged: ThunkOn<ProfileModel>;
    onServiceRemoved: ThunkOn<ProfileModel>;
}

const debouneUpdateSupplier = debounce(
    async (supplier: SupplierInfo, token: string) => {
        await SaveSupplier(supplier, token);
    },
    2000
);

const profileModel: ProfileModel = {
    supplierInfo: {
        termsAccepted: false,
        billingSameAsVisiting: true,
        invoiceAddress: {} as Address,
        visitAddress: addressModel,
    } as SupplierInfo,
    token: '',
    isLoading: false,
    editServiceModel,
    services: [],

    // Computed props
    isProfileComplete: computed((state) => {
        return (
            (state.supplierInfo.orgNumber || 0) > 0 &&
            !!state.supplierInfo.name &&
            (state.supplierInfo.phoneNumber || 0) > 0 &&
            !!state.supplierInfo.bookingMailAddress &&
            !!state.supplierInfo.contactPersonName // &&
            // isAddressComplete(state.supplierInfo.visitAddress) &&
            // (state.supplierInfo.billingSameAsVisiting ||
            //     isAddressComplete(state.supplierInfo.invoiceAddress))
        );
    }),

    // Actions
    setViewService: action((state, payload) => {
        state.viewService = payload;
    }),
    addService: action((state, payload) => {
        state.services.push(payload);
    }),
    removeService: action((state, payload) => {
        const index = state.services.findIndex((x) => x.offeringId === payload);

        if (index !== -1) state.services.splice(index, 1);
    }),
    setIsLoading: action((state, payload) => {
        state.isLoading = payload;
    }),
    setLoadingError: action((state, payload) => {
        state.loadingError = payload;
        state.supplierInfo.legalName = undefined;
        state.supplierInfo.termsAccepted = false;
    }),
    setUser: action((state, payload) => {
        state.token = payload.token ?? '';
        state.user = payload?.user;
    }),

    setFromExternalData: action((state, payload) => {
        state.supplierInfo.orgNumber = parseInt(payload.orgNumber, 10);
        state.supplierInfo.legalName = payload.legalName;
        state.supplierInfo.visitAddress = payload.visitAddress;
    }),
    setSupplier: action((state, payload) => {
        state.supplierInfo = payload;
        state.services = [];
        if (payload.images === null) state.supplierInfo.images = [];
    }),
    setOrgNumber: action((state, payload) => {
        if (payload.toString().length > 9) return;
        state.supplierInfo.orgNumber = payload;
    }),
    setIsAccepted: action((state, payload) => {
        state.supplierInfo.termsAccepted = payload;
    }),
    setSupplierId: action((state, payload) => {
        state.supplierInfo.id = payload;
    }),

    setName: action((state, payload) => {
        state.supplierInfo.name = payload;
    }),
    setCompanyLogo: action((state, payload) => {
        // if (state.supplierInfo)
        state.supplierInfo.logoUrl = payload;
    }),
    setPhoneNumber: action((state, payload) => {
        state.supplierInfo.phoneNumber = payload;
    }),
    setBookingMailAddress: action((state, payload) => {
        state.supplierInfo.bookingMailAddress = payload;
    }),
    setContactPerson: action((state, payload) => {
        state.supplierInfo.contactPersonName = payload;
    }),
    setWebsite: action((state, payload) => {
        state.supplierInfo.websiteUrl = payload;
    }),
    setContactPhone: action((state, payload) => {
        state.supplierInfo.contactPersonPhone = payload;
    }),
    setVisitingAddresses: action((state, payload) => {
        state.supplierInfo.visitAddress.addresses[0] = payload;
    }),
    setVisitingZipCode: action((state, payload) => {
        state.supplierInfo.visitAddress.zipCode = payload;
    }),
    setVisitingPostalName: action((state, payload) => {
        state.supplierInfo.visitAddress.postalName = payload;
    }),
    setBillingSameAsVisiting: action((state, payload) => {
        state.supplierInfo.billingSameAsVisiting = payload;
        if (payload !== true) {
            state.supplierInfo.invoiceAddress = {
                country: {
                    value: 'NO',
                    label: ' Norge',
                },
                zipCode: '',
                postalName: '',
                addresses: [],
            } as Address;
        }
    }),
    setBillingAddresses: action((state, payload) => {
        state.supplierInfo.invoiceAddress.addresses[0] = payload;
    }),
    setBillingZipCode: action((state, payload) => {
        state.supplierInfo.invoiceAddress.zipCode = payload;
    }),
    setBillingPostalName: action((state, payload) => {
        state.supplierInfo.invoiceAddress.postalName = payload;
    }),

    addCompanyImage: action((state, payload) => {
        state.supplierInfo.images = [payload].concat(state.supplierInfo.images);
    }),
    removeCompanyImage: action((state, payload) => {
        const index = state.supplierInfo.images.findIndex((img) => {
            return img.id === payload.id;
        });

        state.supplierInfo.images.splice(index, 1);
    }),

    // thunks
    getSupplier: thunk(async (actions, payload, { getState }) => {
        const { token } = getState();

        const supplier = await GetProfile(token);

        if (!supplier) return;

        actions.setSupplier(supplier);

        const serviceResponse = await LoadServices(token);

        serviceResponse.map((x) => actions.addService(x));

        actions.setIsLoading(false);
    }),
    createNewSupplier: thunk(async (actions, payload, { getState }) => {
        try {
            actions.setIsLoading(true);
            const { supplierInfo, token } = getState();
            if (supplierInfo === undefined) return;

            const result = await SaveSupplier(supplierInfo, token);
            if (result.id) actions.setSupplierId(result.id);
        } finally {
            actions.setIsLoading(false);
        }
    }),
    updateSupplier: thunk(async (actions, payload, { getState }) => {
        try {
            actions.setIsLoading(false);
            const { supplierInfo, token } = getState();
            if (supplierInfo === undefined) return;

            await SaveSupplier(supplierInfo, token);
        } finally {
            actions.setIsLoading(false);
        }
    }),
    uploadLogo: thunk(async (actions, payload, { getState }) => {
        try {
            actions.setIsLoading(true);
            const { supplierInfo, token } = getState();
            if (!supplierInfo || !supplierInfo.id) return;

            const logoUrl = await UploadLogo(
                supplierInfo.id,
                payload.file,
                token
            );
            actions.setCompanyLogo(logoUrl);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        } finally {
            actions.setIsLoading(false);
        }
    }),
    uploadImage: thunk(async (actions, payload, { getState }) => {
        try {
            actions.setIsLoading(true);
            const { supplierInfo, token } = getState();
            if (!supplierInfo || !supplierInfo.id) return;

            const imageResponse = await UploadImage(
                supplierInfo.id,
                payload.file,
                token
            );
            actions.addCompanyImage(imageResponse);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        } finally {
            actions.setIsLoading(false);
        }
    }),
    removeImage: thunk(async (actions, payload, { getState }) => {
        try {
            const { token } = getState();
            actions.setIsLoading(true);
            await DeleteCompanyImage(payload.id, token);
            actions.removeCompanyImage(payload);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        } finally {
            actions.setIsLoading(false);
        }
    }),
    createNewService: thunk(async (actions, _, { getState }) => {
        try {
            const { token } = getState();
            actions.setIsLoading(true);

            const newService = await CreateService(token);

            actions.addService(newService);

            // open in edit mode
            actions.editServiceModel.setEditService(newService);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        } finally {
            actions.setIsLoading(false);
        }
    }),
    deleteProfile: thunk(async (actions, _, { getState }) => {
        try {
            const { token } = getState();
            actions.setIsLoading(true);

            await DeleteProfile(token);

            actions.setSupplier({} as SupplierInfo);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        } finally {
            actions.setIsLoading(false);
        }
    }),
    // ActionOn
    onEditServiceHasChanges: actionOn(
        (_, storeActions) => [
            storeActions.profile.editServiceModel.setHasChanges,
            storeActions.profile.editServiceModel.removeImage,
            storeActions.profile.editServiceModel.addImage,
        ],
        (state) => {
            if (!state.editServiceModel?.service) return;

            const { service } = state.editServiceModel;

            const idx = state.services.findIndex(
                (x) => x.offeringId === service.offeringId
            );

            if (idx > -1) state.services[idx] = service;
        }
    ),

    // ThunkOn
    onServiceRemoved: thunkOn(
        (actions) => actions.removeService,
        async (_, target, { getState }) => {
            const { token } = getState();
            const offeringId = target.payload;

            await DeleteService(offeringId, token);
        }
    ),
    onProfileDataChanged: thunkOn(
        (actions) => [
            actions.setName,
            actions.setPhoneNumber,
            actions.setBookingMailAddress,
            actions.setContactPerson,
            actions.setWebsite,
            actions.setContactPhone,
            actions.setVisitingAddresses,
            actions.setVisitingZipCode,
            actions.setVisitingPostalName,
            actions.setBillingAddresses,
            actions.setBillingZipCode,
            actions.setBillingPostalName,
        ],
        (actions, target, { getState }) => {
            const { supplierInfo, token } = getState();

            if (!supplierInfo || !supplierInfo.id) return;

            debouneUpdateSupplier(supplierInfo, token);
        }
    ),
    onOrgNumberChanged: thunkOn(
        (actions) => actions.setOrgNumber,
        async (actions, target) => {
            const orgNumber = target.payload;
            const pattern = /^\d{9}$/;

            if (!orgNumber.toString().match(pattern)) return;

            actions.setIsLoading(true);

            try {
                const companyInfo = await GetCompanyInfoByOrgnumber(orgNumber);
                actions.setFromExternalData(companyInfo);
            } catch (err) {
                actions.setLoadingError({
                    severity: Severity.Warning,
                    title: 'Organization number not found in Brønnøysundregisteret',
                    message:
                        'Please verify that you have entered correct organization number',
                });
            } finally {
                actions.setIsLoading(false);
            }
        }
    ),
};

export default profileModel;
