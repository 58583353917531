import React, { lazy, Suspense } from 'react';
import { CssBaseline } from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import Layout from './components/Layout';
import { theme } from './theme/appTheme';
import Auth0ProviderWithHistory from './Auth/Auth0ProviderWithHistory';
import ProtectedRoute from './Auth/ProtectedRoute';

const HomePage = lazy(() => import('./pages/Home'));
const EventsPage = lazy(() => import('./pages/Events/EventLayout'));
const AboutPage = lazy(() => import('./pages/About'));
const ProfilePages = lazy(() => import('./pages/Profile/Company'));

const App: React.FC = () => {
    return (
        <>
            <Router>
                <Auth0ProviderWithHistory>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Layout>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Switch>
                                    <Route
                                        exact
                                        path="/"
                                        component={HomePage}
                                    />
                                    <Route
                                        path="/event"
                                        component={EventsPage}
                                    />
                                    {/* <Route path="/register" component={RegisterPages} /> */}
                                    <ProtectedRoute
                                        path="/profile"
                                        component={ProfilePages}
                                    />
                                    <Route
                                        exact
                                        path="/about"
                                        component={AboutPage}
                                    />
                                </Switch>
                            </Suspense>
                        </Layout>
                    </ThemeProvider>
                </Auth0ProviderWithHistory>
            </Router>
        </>
    );
};

export default App;
