import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';

export const LoginButton: React.FC = () => {
    const { t } = useTranslation(['navbar']);
    const { loginWithRedirect } = useAuth0();
    const location = useLocation();

    return (
        <Button
            color="secondary"
            onClick={() =>
                loginWithRedirect({
                    appState: {
                        returnTo: location.pathname,
                    },
                })
            }
        >
            {t('navbar:login')}
        </Button>
    );
};
