/* eslint-disable @typescript-eslint/no-explicit-any */
import { Category } from '../../../store/models/events/constants';
import { Entity } from '../../types/entities';
import { setPropValue } from '../../utils/set-prop-value';

export const mapToCategory = (category: string): Category => {
    switch (category.toLocaleLowerCase()) {
        case 'food and beverage':
            return Category.FoodAndBeverage;
        case 'speaker/lecturer':
            return Category.SpeakerLecturer;
        default:
            return Category[category as keyof typeof Category];
    }
};

const mapToEntity = (entity: any): Entity =>
    ({
        ...setPropValue('code', entity, 'code'),
        ...setPropValue('category', entity, 'area', (area) =>
            mapToCategory(area as string)
        ),
        ...setPropValue('heading', entity, 'heading'),
        ...setPropValue('headingCode', entity, 'headingCode'),
        ...setPropValue('sequence', entity, 'sequence'),
        ...setPropValue('name', entity, 'name'),
        ...setPropValue('tags', entity, 'tags'),
    } as Entity);

export const mapToEntities = (entities: any[]): Entity[] => {
    return entities.map((entity: any) => mapToEntity(entity));
};
