/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { setPropValue } from '../../../infrastructure/utils/set-prop-value';
import { ImageDescriptor } from '../../../store/models/profile/profile';
import { Hours, Service, Day } from './types';

export const mapImage = (image: any): ImageDescriptor => {
    return {
        ...setPropValue('id', image, 'id'),
        ...setPropValue('description', image, 'description'),
        ...setPropValue('url', image, 'url'),
    } as ImageDescriptor;
};

const mapImages = (images: any[]): ImageDescriptor[] =>
    images.map((i: any) => mapImage(i));

const mapDay = (day: any): Day =>
    ({
        ...setPropValue('from', day, 'from'),
        ...setPropValue('to', day, 'to'),
    } as Day);

const mapHours = (hours: any): Hours =>
    ({
        ...setPropValue('mon', hours, 'mon', (d) =>
            d ? mapDay(d) : undefined
        ),
        ...setPropValue('tue', hours, 'tue', (d) =>
            d ? mapDay(d) : undefined
        ),
        ...setPropValue('wed', hours, 'wed', (d) =>
            d ? mapDay(d) : undefined
        ),
        ...setPropValue('thu', hours, 'thu', (d) =>
            d ? mapDay(d) : undefined
        ),
        ...setPropValue('fri', hours, 'fri', (d) =>
            d ? mapDay(d) : undefined
        ),
        ...setPropValue('sat', hours, 'sat', (d) =>
            d ? mapDay(d) : undefined
        ),
        ...setPropValue('sun', hours, 'sun', (d) =>
            d ? mapDay(d) : undefined
        ),
    } as Hours);

export const mapToService = (service: any): Service =>
    ({
        ...setPropValue('id', service, 'id'),
        ...setPropValue('offeringId', service, 'offeringId'),
        ...setPropValue('title', service, 'title'),
        ...setPropValue('description', service, 'description'),
        ...setPropValue('minNumberOfGuest', service, 'minNumberOfPeople'),
        ...setPropValue('maxNumberOfGuest', service, 'maxNumberOfPeople'),
        ...setPropValue('minPrice', service, 'priceFrom'),
        ...setPropValue('maxPrice', service, 'priceFrom'),
        ...setPropValue('entityIds', service, 'entityIds'),
        ...setPropValue('locations', service, 'locations'),
        ...setPropValue('hours', service, 'hours', (h) => mapHours(h)),
        ...setPropValue('images', service, 'images', (d) =>
            mapImages(d as any[])
        ),
    } as Service);

export const mapToServices = (services: any): Service[] =>
    services.map((service: any) => mapToService(service));
