import { action, Action, actionOn, ActionOn } from 'easy-peasy';
import { StoreModel } from '..';

export enum Severity {
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
    Success = 'success',
}

interface CreateNotification {
    severity: Severity;
    header: string;
    text: string;
}
export interface NotificationModel {
    show: boolean;
    serverity: Severity;
    header: string;
    text: string;

    createNotification: Action<NotificationModel, CreateNotification>;
    acknowledge: Action<NotificationModel>;

    onLoadingError: ActionOn<NotificationModel, StoreModel>;
}

const notification: NotificationModel = {
    show: false,
    serverity: Severity.Info,
    header: '',
    text: '',

    acknowledge: action((state) => {
        state.show = false;
    }),
    createNotification: action((state, payload) => {
        state.serverity = payload.severity;
        state.header = payload.header;
        state.text = payload.text;

        state.show = true;
    }),
    onLoadingError: actionOn(
        (_, storeActions) => storeActions.profile.setLoadingError,
        (state, target) => {
            const { payload } = target;
            state.serverity = payload.severity;
            state.header = payload.title;
            state.text = payload.message;

            state.show = true;
        }
    ),
};

export default notification;
