import { action, createStore } from 'easy-peasy';
import model from './models';

let initalState = {};

const store = createStore({
    ...model,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reset: action((state, payload) => ({
        ...initalState,
    })),
});

initalState = store.getState();

export default store;
