import React, { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from '../assets/images/MyhattLogo.png';
import NavBar from './NavBar';
import { useStoreActions, useStoreState } from '../store/hooks';
import { AuthenticationButton } from '../Auth/AuthenticationButton';
import { IUser } from '../store/models/user/user';
import UserInformationPopup from './user/UserInformationPopup';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
    children: NonNullable<ReactNode>;
};

const Layout: React.FC<Props> = ({ children }) => {
    const history = useHistory();
    // const location = useLocation();
    const { user, isAuthenticated } = useAuth0();

    const { show, text, header, serverity } = useStoreState(
        (state) => state.notifications
    );
    const { isLoading } = useStoreState((state) => state);
    const { canSendRequest } = useStoreState((state) => state.event);
    const { showInformationRequired } = useStoreState((state) => state.user);

    const { acknowledge } = useStoreActions((actions) => actions.notifications);
    const { loadEntities } = useStoreActions((actions) => actions.externalData);
    const { setCurrentUser } = useStoreActions((actions) => actions.user);

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ): void => {
        if (reason === 'clickaway') {
            return;
        }

        acknowledge();
    };

    useEffect(() => {
        loadEntities();
    }, [loadEntities]);

    useEffect(() => {
        if (!isAuthenticated || !user) return;

        // eslint-disable-next-line camelcase
        const { name, email, given_name, family_name } = user;

        const address = JSON.parse(user['https://myhatt.com/address'] ?? '{}');

        setCurrentUser({
            name,
            email,
            firstName: given_name,
            lastName: family_name,
            phone: user['https://myhatt.com/phoneNumber'],
            streetname: address.streetName,
            zipCode: address.zipCode,
            city: address.city,
            companyName: user['https://myhatt.com/companyName'] ?? '',
        } as IUser);

        if (history.location.pathname === '/' && canSendRequest)
            history.push('/event/new/request');
    }, [canSendRequest, history, isAuthenticated, setCurrentUser, user]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <UserInformationPopup
                id="userinformation"
                open={showInformationRequired}
            />

            <Snackbar
                open={show}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
            >
                <Alert
                    onClose={handleClose}
                    severity={serverity}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{header}</AlertTitle>
                    {text}
                </Alert>
            </Snackbar>
            <AppBar
                position="fixed"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Toolbar>
                    <Link href="https://www.myhatt.com" underline="always">
                        <img
                            src={Logo}
                            alt="myhatt logo"
                            style={{ height: 36 }}
                        />
                    </Link>

                    <Box sx={{ flex: 1 }}>
                        <NavBar />
                    </Box>
                    <AuthenticationButton />
                </Toolbar>
            </AppBar>

            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    backgroundColor: '#2E3D4A',
                }}
            >
                {children}
            </Box>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default Layout;
