import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import home from './en/home.json';
import navbarEn from './en/navigation.json';
import navbarNo from './no/navigation.json';
import about from './en/about.json';
import commonEn from './en/common.json';
import commonNo from './no/common.json';
import eventsEn from './en/events.json';
import eventsNo from './no/events.json';
import register from './en/register.json';
import profileEn from './en/profile.json';
import profileNo from './no/profile.json';
import serviceEn from './en/service.json';
import serviceNo from './no/service.json';
import authErrorEn from './en/authError.json';
import authErrorNo from './no/authError.json';

export const defaultNS = 'common';
export const resources = {
    en: {
        home,
        navbar: navbarEn,
        about,
        common: commonEn,
        events: eventsEn,
        register,
        profile: profileEn,
        service: serviceEn,
        authError: authErrorEn,
    },
    no: {
        home,
        navbar: navbarNo,
        about,
        common: commonNo,
        events: eventsNo,
        register,
        profile: profileNo,
        service: serviceNo,
        authError: authErrorNo,
    },
} as const;

i18n.use(initReactI18next).init({
    lng: 'no',
    ns: [
        'home',
        'navbar',
        'about',
        'common',
        'events',
        'register',
        'profile',
        'service',
        'authError',
    ],
    defaultNS,
    resources,
});
