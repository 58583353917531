import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';

import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useStoreActions, useStoreState } from '../../store/hooks';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
    id: string;
    open: boolean;
}

const UserInformationPopup: React.FC<Props> = ({ id, open }) => {
    const { t } = useTranslation(['common', 'profile']);
    const { currentUser, isSaving, isProfileComplete } = useStoreState(
        (state) => state.user
    );
    const {
        setFirstName,
        setLastName,
        setCompanyName,
        setPhone,
        setStreetname,
        setZipcode,
        setCity,
        updateProfile,
    } = useStoreActions((actions) => actions.user);
    const { logout } = useAuth0();

    const cancelHandler = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        event.preventDefault();

        logout({ returnTo: window.location.origin });
    };
    return (
        <Dialog
            id={id}
            open={open}
            TransitionComponent={Transition}
            aria-describedby="user-information-required"
        >
            <DialogTitle>{t('profile:completeProfile')}</DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                    p: 2,
                }}
            >
                <DialogContentText
                    color="secondary"
                    id="user-information-required-description"
                >
                    {t('profile:completeProfileInformation')}
                </DialogContentText>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'stretch',
                        gap: 1,
                    }}
                >
                    <TextField
                        label={t('profile:firstName')}
                        required
                        value={currentUser?.firstName || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFirstName(e.target.value)
                        }
                    />
                    <TextField
                        label={t('profile:lastName')}
                        required
                        value={currentUser?.lastName || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setLastName(e.target.value)
                        }
                    />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'stretch',
                        gap: 1,
                    }}
                >
                    <TextField
                        sx={{ flex: 1 }}
                        label={t('profile:phone')}
                        fullWidth
                        required
                        value={currentUser?.phone || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setPhone(Number(e.target.value))
                        }
                    />
                    <TextField
                        sx={{ flex: 2 }}
                        label={t('profile:email')}
                        fullWidth
                        required
                        disabled
                        value={currentUser?.email || ''}
                        // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        //     setEmail(Number(e.target.value))
                        // }
                    />
                </Box>
                <TextField
                    label={t('profile:addressInformation.streetName')}
                    fullWidth
                    required
                    value={currentUser?.streetname || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setStreetname(e.target.value)
                    }
                />
                <TextField
                    label={t('profile:addressInformation.zipCode')}
                    fullWidth
                    required
                    value={currentUser?.zipCode || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setZipcode(e.target.value)
                    }
                />
                <TextField
                    label={t('profile:addressInformation.city')}
                    fullWidth
                    required
                    value={currentUser?.city || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCity(e.target.value)
                    }
                />
                <TextField
                    label={t('profile:publicName')}
                    fullWidth
                    value={currentUser?.companyName || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCompanyName(e.target.value)
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    color="secondary"
                    onClick={cancelHandler}
                >
                    {t('common:cancel')}
                </Button>
                <LoadingButton
                    disabled={!isProfileComplete}
                    loading={isSaving}
                    onClick={() => updateProfile()}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    color="secondary"
                    variant="text"
                >
                    {t('common:save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default UserInformationPopup;
