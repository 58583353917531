import { APP_CONFIG } from '../../../appConfig';
import { mapToServices } from '../utils/map';
import { Service } from '../utils/types';

export const LoadServices = async (token: string): Promise<Service[]> => {
    const response = await fetch(`${APP_CONFIG.baseApiUrl}/offering`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
    });

    return mapToServices(await response.json());
};
