import { APP_CONFIG } from '../../appConfig';
import { mapToAreas } from './map';

import { Area } from './types';

export const getAreas = async (): Promise<Area[]> => {
    const response = await fetch(`${APP_CONFIG.baseApiUrl}/locationproxy`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await response.json();

    return mapToAreas(data);
};
