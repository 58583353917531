import { APP_CONFIG } from '../../../appConfig';

export const DeleteService = async (
    offeringId: string,
    token: string
): Promise<void> => {
    await fetch(`${APP_CONFIG.baseApiUrl}/offering/${offeringId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
    });
};
