import {
    action,
    Action,
    computed,
    Computed,
    thunkOn,
    ThunkOn,
} from 'easy-peasy';

interface Country {
    value: string;
    label: string;
}
interface Address {
    country: Country;
    zipCode: string;
    postalName: string;
    addresses: string[];

    setZipCode: Action<Address, string>;
    setAddresses: Action<Address, string>;
    setPostalName: Action<Address, string>;
}

const CreateAddressModel = (address = {} as Address): Address => {
    const { zipCode, postalName, addresses } = address;

    const model: Address = {
        country: { value: 'No', label: 'Norge' } as Country,
        zipCode,
        postalName,
        addresses,

        setZipCode: action((state, payload) => {
            state.zipCode = payload;
        }),
        setPostalName: action((state, payload) => {
            state.postalName = payload;
        }),
        setAddresses: action((state, payload) => {
            state.addresses = [payload];
        }),
    };

    return model;
};

export interface CompanyInformationModel {
    isLoading: boolean;

    orgNumber?: number;
    legalName?: string;
    displayName?: string;
    bookingMailAddress?: string;
    phoneNumber?: number;
    website?: string;
    visitAddress: Address;
    invoiceAddress: Address;

    contactPerson?: string;
    contactPhone?: number;
    accepted: boolean;
    // loadingError?: LoadingError;

    isCompleted: Computed<CompanyInformationModel, boolean>;

    toggleIsLoading: Action<CompanyInformationModel>;
    setOrgNumber: Action<CompanyInformationModel, number>;
    setLegalName: Action<CompanyInformationModel, string | undefined>;
    setDisplayName: Action<CompanyInformationModel, string | undefined>;
    setBookingMailAddress: Action<CompanyInformationModel, string | undefined>;
    setPhoneNumber: Action<CompanyInformationModel, number | undefined>;
    setWebsite: Action<CompanyInformationModel, string | undefined>;
    setContactPerson: Action<CompanyInformationModel, string | undefined>;
    setContactPhone: Action<CompanyInformationModel, number | undefined>;
    setAccepted: Action<CompanyInformationModel, boolean>;
    setVisitingAddress: Action<CompanyInformationModel, Address>;
    setInvoiceAddress: Action<CompanyInformationModel, Address>;

    onOrgNumberChanged: ThunkOn<CompanyInformationModel>;
}

const basicInformationModel: CompanyInformationModel = {
    isLoading: false,
    accepted: false,
    visitAddress: CreateAddressModel(),
    invoiceAddress: CreateAddressModel(),

    toggleIsLoading: action((state) => {
        state.isLoading = !state.isLoading;
    }),

    setOrgNumber: action((state, payload) => {
        if (payload.toString().length > 9) return;
        state.orgNumber = payload;
    }),
    setLegalName: action((state, payload) => {
        state.legalName = payload;
    }),
    setDisplayName: action((state, payload) => {
        state.displayName = payload;
    }),
    setBookingMailAddress: action((state, payload) => {
        state.bookingMailAddress = payload;
    }),
    setPhoneNumber: action((state, payload) => {
        state.phoneNumber = payload;
    }),
    setWebsite: action((state, payload) => {
        state.website = payload;
    }),
    setContactPerson: action((state, payload) => {
        state.contactPerson = payload;
    }),
    setContactPhone: action((state, payload) => {
        state.contactPhone = payload;
    }),
    setAccepted: action((state, payload) => {
        state.accepted = payload;
    }),
    setVisitingAddress: action((state, payload) => {
        state.visitAddress = payload;
    }),
    setInvoiceAddress: action((state, payload) => {
        state.invoiceAddress = payload;
    }),
    // setLoadingError: action((state, payload) => {
    //     state.loadingError = payload;

    //     state.legalName = undefined;
    //     state.displayName = undefined;
    //     state.phoneNumber = undefined;
    //     state.bookingMailAddress = undefined;
    //     state.contactPerson = undefined;
    //     state.website = undefined;
    //     state.contactPhone = undefined;
    //     state.accepted = false;
    // }),

    isCompleted: computed(
        (state) =>
            state.accepted &&
            !!state.orgNumber &&
            state.orgNumber.toString().length === 9 &&
            !!state.displayName &&
            !!state.phoneNumber &&
            !!state.contactPerson
    ),

    onOrgNumberChanged: thunkOn(
        (actions) => actions.setOrgNumber,

        async (actions, target) => {
            const orgNumber = target.payload;
            const pattern = /^\d{9}$/;

            if (!orgNumber.toString().match(pattern)) return;

            actions.toggleIsLoading();

            // try {
            //     const response = await GetCompanyInfoByOrgnumber(orgNumber);

            //     actions.setLegalName(response.legalName);
            //     // actions.setDisplayName(response.displayName);
            //     // actions.setPhoneNumber(response.phoneNumber);
            //     // actions.setBookingMailAddress(response.bookingMailAddress);
            //     // actions.setContactPerson(response.contactPerson);
            //     // actions.setWebsite(response.website);
            //     // actions.setContactPhone(response.contactPhone);

            //     // actions.setVisitingAddress(
            //     //     CreateAddressModel(response.visitAddress)
            //     // );
            //     // actions.setInvoiceAddress(
            //     //     CreateAddressModel(response.invoiceAddress)
            //     // );
            // } catch (err) {
            //     actions.setLoadingError({
            //         severity: Severity.Warning,
            //         title: 'Organization number not found in Brønnøysundregisteret',
            //         message:
            //             'Please verify that you have entered correct organization number',
            //     });
            // } finally {
            //     actions.toggleIsLoading();
            // }

            // setTimeactions.toggleIsLoading();
        }
    ),
};

export interface RegisterModel {
    basicInformation: CompanyInformationModel;
}

const registerModel: RegisterModel = {
    basicInformation: basicInformationModel,
};

export default registerModel;
