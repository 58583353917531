import { APP_CONFIG } from '../../../appConfig';

export const DeleteImage = async (
    offeringId: string,
    imageId: string,
    token: string
): Promise<void> => {
    await fetch(
        `${APP_CONFIG.baseApiUrl}/offering/${offeringId}/image/${imageId}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'DELETE',
        }
    );
};
