import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';

import store from '../store';

export const LogoutButton: React.FC = () => {
    const { t } = useTranslation(['navbar']);
    const { logout } = useAuth0();

    const clickHandler = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        store.dispatch.reset({});

        logout({ returnTo: window.location.origin });
    };

    return (
        <Button color="secondary" onClick={clickHandler}>
            {t('navbar:logout')}
        </Button>
    );
};
