import { action, Action, Computed, computed, thunk, Thunk } from 'easy-peasy';
import { Category, EventType } from './constants';
import { Location } from '../externalData/interfaces';
import { GetVendors } from '../../../services/myhattClient';
import { EventStepperMenu, stepperMenu } from './stepperMenu';
import {
    SendRequestPayload,
    VendorPayload,
} from '../../../components/events/types/types';
import { SendRequest } from '../../../components/events/remote-data/send-request';
import { Hours } from '../../../components/service/utils/types';
import { Address, ImageDescriptor } from '../profile/profile';
import { StoreModel } from '..';

export interface VendorCategory {
    code: string;
    area: string;
    name: string;
    heading: string;
    headingCode: string;
    sequence: number;
    isSelected: boolean;
}
export interface Vendor {
    id: string;
    supplierId: string;
    supplierName: string;
    serviceTitle: string;
    logoUrl: string;
    description: string;
    categories: VendorCategory[];
    hours: Hours;
    images: ImageDescriptor[];
    minNumberOfPeople: number;
    maxNumberOfPeople: number;
    priceFrom: number;
    priceTo: number;
    visitingAddress: Address;
}
interface SelectedVendor {
    category: string;
    localizedCategory: string;
    vendor: Vendor;
    comment?: string;
}
interface SetCommentPayload {
    vendorId: string;
    category: string;
    comment: string;
}

interface SetEventTypePayload {
    eventType: EventType;
    localizedValue: string;
}

export interface EventModel {
    stepMenu: EventStepperMenu;
    type?: EventType;
    localizedEventType?: string;
    title?: string;
    eventDate?: Date;
    numberOfGuest?: number;
    // locations: Location[];
    selectedCategories: Category[];
    selectedVendors: SelectedVendor[];
    vendors: Vendor[];
    currentCategory?: Category;
    popupVendor?: Vendor;

    detailsCompleted: Computed<EventModel, boolean>;
    categoryCompleted: Computed<EventModel, boolean>;
    // locationSummary: Computed<EventModel, string>;
    canSendRequest: Computed<EventModel, boolean>;

    init: Action<EventModel>;
    setEventType: Action<EventModel, SetEventTypePayload>;
    addCatgoryType: Action<EventModel, Category>;
    setTitle: Action<EventModel, string>;
    setEventDate: Action<EventModel, Date>;
    setNumberOfGuest: Action<EventModel, number>;
    // addLocation: Action<EventModel, Location>;
    // removeLocation: Action<EventModel, Location>;
    setVendors: Action<EventModel, Vendor[]>;
    setSelectedVendor: Action<EventModel, SelectedVendor>;
    addCommentToVendor: Action<EventModel, SetCommentPayload>;
    setCurrentCategory: Action<EventModel, Category | undefined>;
    setPopupVendor: Action<EventModel, Vendor | undefined>;

    getVendors: Thunk<EventModel, undefined>;
    sendRequest: Thunk<EventModel, undefined>;
}

const eventModel: EventModel = {
    stepMenu: stepperMenu,
    // locations: [],
    selectedCategories: [],
    vendors: [],
    selectedVendors: [],

    detailsCompleted: computed(
        (state) =>
            !!state.title && (state.numberOfGuest || 0) > 0 && !!state.eventDate // &&
        // state.locations.length > 0
    ),
    categoryCompleted: computed((state) => state.selectedCategories.length > 0),

    // locationSummary: computed((state) =>
    //     state.locations.map((location) => location.label).join(', ')
    // ),
    canSendRequest: computed(
        [
            (state) => state.selectedVendors,
            (_, storeModel) => (storeModel as StoreModel).user,
        ],
        (vendors, user) => (user.currentUser ?? false) && vendors.length > 0
    ),
    init: action((state) => {
        state.stepMenu = stepperMenu;
        // state.locations = [];
        state.selectedCategories = [];
        state.vendors = [];
        state.selectedVendors = [];
        state.currentCategory = undefined;
        state.type = undefined;
        state.localizedEventType = undefined;
        state.title = undefined;
        state.eventDate = undefined;
        state.numberOfGuest = undefined;
        // state.name = undefined;
        // state.email = undefined;
        // state.phoneNo = undefined;
    }),

    setCurrentCategory: action((state, payload) => {
        state.currentCategory = payload;
    }),
    setPopupVendor: action((state, payload) => {
        state.popupVendor = payload;
    }),
    addCatgoryType: action((state, payload) => {
        const index = state.selectedCategories.indexOf(payload, 0);
        if (index > -1) {
            state.selectedVendors = state.selectedVendors.filter(
                (x) => x.category !== payload.toLocaleLowerCase()
            );

            // (state.selectedVendors).find(
            //     (x) => x.category !== payload.toLocaleLowerCase()
            // ) ?? [];
            state.selectedCategories.splice(index, 1);
        } else {
            state.selectedCategories.push(payload);
        }
    }),

    setEventType: action((state, payload) => {
        state.type = payload.eventType;
        state.localizedEventType = payload.localizedValue;
    }),
    // setName: action((state, payload) => {
    //     state.name = payload;
    // }),
    // setEmail: action((state, payload) => {
    //     state.email = payload;
    // }),
    // setPhoneNo: action((state, payload) => {
    //     state.phoneNo = payload;
    // }),
    setTitle: action((state, payload) => {
        state.title = payload;
    }),
    setEventDate: action((state, payload) => {
        state.eventDate = payload;
    }),
    setNumberOfGuest: action((state, payload) => {
        state.numberOfGuest = payload;
    }),
    // addLocation: action((state, payload) => {
    //     state.locations.push(payload);
    // }),
    // removeLocation: action((state, payload) => {
    //     const idx = state.locations.findIndex(
    //         (obj) => obj.value === payload.value
    //     );

    //     if (idx > -1) state.locations.splice(idx, 1);
    // }),
    setVendors: action((state, payload) => {
        state.vendors = payload;
    }),

    setSelectedVendor: action((state, payload) => {
        const idx = state.selectedVendors.findIndex(
            (obj) =>
                obj.category === payload.category &&
                obj.vendor.id === payload.vendor.id
        );
        if (idx > -1) state.selectedVendors.splice(idx, 1);
        else state.selectedVendors.push(payload);
    }),

    addCommentToVendor: action((state, payload) => {
        const idx = state.selectedVendors.findIndex(
            (x) =>
                x.vendor.id === payload.vendorId &&
                x.category === payload.category
        );

        if (idx === -1) return;

        const itemToUpdate = state.selectedVendors[idx];

        itemToUpdate.comment = payload.comment;

        state.selectedVendors[idx] = itemToUpdate;
    }),

    // Thunks
    getVendors: thunk(async (actions, payload, { getState }) => {
        try {
            const state = getState();
            const res = await GetVendors(state.selectedCategories);

            actions.setVendors(res);
        } catch (error) {
            // console.log(error);
        }
    }),
    sendRequest: thunk(
        async (actions, payload, { getState, getStoreState }) => {
            try {
                const state = getState();
                const { user } = getStoreState() as StoreModel;

                const data = {
                    name: user.currentUser?.name,
                    email: user.currentUser?.email,
                    companyName: user.currentUser?.companyName,
                    phoneNo: user.currentUser?.phone,
                    streetname: user.currentUser?.streetname,
                    zipCode: user.currentUser?.zipCode,
                    city: user.currentUser?.city,
                    // locations: state.locationSummary,
                    title: state.title,
                    eventDate: state.eventDate ?? Date,
                    numberOfGuests: state.numberOfGuest ?? 0,
                    typeOfEvent: state.localizedEventType ?? '',
                    vendors: state.selectedVendors.map((v) => {
                        return {
                            supplierId: v.vendor.supplierId,
                            serviceTitle: v.vendor.serviceTitle,
                            comment: v.comment ?? '',
                        } as VendorPayload;
                    }),
                } as SendRequestPayload;

                await SendRequest(data);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }
    ),
};

export default eventModel;
