/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import get from 'lodash/get';
import has from 'lodash/has';

export const setPropValue = (
    prop: string,
    target: {
        [key: string]: any;
    },
    targetProp?: string,
    method?: (input: string | object[] | object | Date) => any
): object | false =>
    has(target, targetProp || prop) && {
        [prop]: method
            ? method(get(target, targetProp || prop))
            : get(target, targetProp || prop),
    };
