import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { SaveUserProfile } from '../../../components/profile/remote-data';
import { StoreModel } from '../index';

export interface IUser {
    email: string;
    name: string;
    firstName?: string;
    lastName?: string;
    phone?: number;
    streetname?: string;
    zipCode?: string;
    city?: string;
    companyName?: string;
}

export interface UserModel {
    currentUser?: IUser;
    isSaving: boolean;
    showInformationRequired: boolean;

    isProfileComplete: Computed<UserModel, boolean>;

    setCurrentUser: Action<UserModel, IUser | undefined>;
    setFirstName: Action<UserModel, string>;
    setLastName: Action<UserModel, string>;
    setPhone: Action<UserModel, number>;
    setStreetname: Action<UserModel, string>;
    setZipcode: Action<UserModel, string>;
    setCity: Action<UserModel, string>;
    setCompanyName: Action<UserModel, string>;
    setIsSaving: Action<UserModel, boolean>;
    setShowInformationRequired: Action<UserModel>;

    updateProfile: Thunk<UserModel, undefined, unknown, StoreModel>;
}

function isProfileComplete(user?: IUser): boolean {
    return (
        !!user?.firstName &&
        !!user?.lastName &&
        !!user?.streetname &&
        !!user?.zipCode &&
        !!user?.city &&
        (!!user?.phone || 0) > 0
    );
}

const userModel: UserModel = {
    showInformationRequired: false,
    isSaving: false,

    isProfileComplete: computed((state) =>
        isProfileComplete(state.currentUser)
    ),

    setCurrentUser: action((state, payload) => {
        state.currentUser = payload;
        state.showInformationRequired = !isProfileComplete(payload);
    }),

    setFirstName: action((state, payload) => {
        if (!state.currentUser) return;

        state.currentUser.firstName = payload;
    }),
    setLastName: action((state, payload) => {
        if (!state.currentUser) return;

        state.currentUser.lastName = payload;
    }),
    setPhone: action((state, payload) => {
        if (!state.currentUser) return;

        state.currentUser.phone = payload;
    }),
    setStreetname: action((state, payload) => {
        if (!state.currentUser) return;

        state.currentUser.streetname = payload;
    }),
    setZipcode: action((state, payload) => {
        if (!state.currentUser) return;

        state.currentUser.zipCode = payload;
    }),
    setCity: action((state, payload) => {
        if (!state.currentUser) return;

        state.currentUser.city = payload;
    }),
    setCompanyName: action((state, payload) => {
        if (!state.currentUser) return;

        state.currentUser.companyName = payload;
    }),
    setIsSaving: action((state, payload) => {
        state.isSaving = payload;
    }),
    setShowInformationRequired: action((state) => {
        state.showInformationRequired = false;
    }),

    updateProfile: thunk(async (actions, _, helpers) => {
        actions.setIsSaving(true);

        const { profile, user } = helpers.getStoreState();

        if (user.currentUser)
            await SaveUserProfile(user.currentUser, profile.token);

        actions.setIsSaving(false);
        actions.setShowInformationRequired();
    }),
};

export default userModel;
