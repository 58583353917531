import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useStoreActions, useStoreState } from '../store/hooks';

const useStyles = makeStyles(() =>
    createStyles({
        navlink: {
            fontSize: 16,
            color: '#FFFFFF',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
                textDecorationColor: '#FCCD60',
                textDecorationThickness: 3,
                textUnderlinePosition: 'under',
            },
        },
        activeNavlink: {
            textDecoration: 'underline',
            textDecorationColor: '#FCCD60',
            textDecorationThickness: 3,
            textUnderlinePosition: 'under',
        },
    })
);

const NavBar: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation(['navbar']);
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

    const { supplierInfo } = useStoreState((state) => state.profile);
    const { init } = useStoreActions((actions) => actions.event);
    const { setUser, getSupplier, setIsLoading } = useStoreActions(
        (actions) => actions.profile
    );

    const fetchSupplier = useCallback(async () => {
        if (!isAuthenticated) {
            setUser({ token: undefined, user: undefined });
            return;
        }

        setIsLoading(true);

        const token = await getAccessTokenSilently();
        setUser({ token, user });

        await getSupplier();

        setIsLoading(false);
    }, [
        getAccessTokenSilently,
        getSupplier,
        isAuthenticated,
        setIsLoading,
        setUser,
        user,
    ]);

    useEffect(() => {
        fetchSupplier();
    }, [fetchSupplier]);
    // const preventDefault = (event: React.SyntheticEvent): void =>
    //     event.preventDefault();

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                typography: 'body1',
                '& > :not(style) + :not(style)': {
                    ml: 2,
                },
            }}
        >
            <NavLink
                className={classes.navlink}
                to="/event"
                activeClassName={classes.activeNavlink}
                onClick={() => init()}
            >
                {t('navbar:event')}
            </NavLink>
            <NavLink
                className={classes.navlink}
                to="/profile"
                activeClassName={classes.activeNavlink}
            >
                {supplierInfo?.id
                    ? t('navbar:myServices')
                    : t('navbar:becomeAVendor')}
            </NavLink>
            <Link
                href="https://www.myhatt.com/om-oss"
                className={classes.navlink}
            >
                {t('navbar:about')}
            </Link>
            <Link
                href="https://www.myhatt.com/blogg"
                className={classes.navlink}
            >
                {t('navbar:blog')}
            </Link>
            <Link
                href="https://www.myhatt.com/faq-kontakt"
                className={classes.navlink}
            >
                {t('navbar:faq')}
            </Link>
        </Box>
    );
    // <Container maxWidth="sm">
    //     <Grid container>

    // {links.map((link) => (
    //     <Grid item xs={3} key={link.displayText}>
    //         <NavLink
    //             className={classes.navlink}
    //             to={link.path}
    //             activeClassName={classes.activeNavlink}
    //             exact
    //         >
    //             {link.displayText}
    //         </NavLink>
    //     </Grid>
    // ))}
    //     </Grid>
    // </Container>
    //     );
};

export default NavBar;
