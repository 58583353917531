import { APP_CONFIG } from '../../../appConfig';
import { SendRequestPayload } from '../types/types';
// import {
//     ImageDescriptor,
//     SupplierInfo,
// } from '../../store/models/profile/profile';

export const SendRequest = async (
    payload: SendRequestPayload
): Promise<void> => {
    // const data = new FormData();

    // const response =
    await fetch(`${APP_CONFIG.baseApiUrl}/event`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
    });
    // const result = await response.json();

    // return result.path;
};
