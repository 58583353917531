import { APP_CONFIG } from '../../../appConfig';
import { mapToService } from '../utils/map';
import { Service } from '../utils/types';

export const CreateService = async (token: string): Promise<Service> => {
    const response = await fetch(`${APP_CONFIG.baseApiUrl}/offering`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
    });

    return mapToService(await response.json());
};
