import { action, Action, actionOn, ActionOn } from 'easy-peasy';
import { StoreModel } from '..';
import { Vendor } from './event';

export interface EventStepperMenu {
    items: EventStepperItem[];

    detailsNameIsSet: boolean;
    detailsDateIsSet: boolean;
    detailsNumberOfGuestIsSet: boolean;
    detailsLocationsIsSet: boolean;

    toggleActiveItem: Action<EventStepperMenu, string>;

    onCategoryChanged: ActionOn<EventStepperMenu, StoreModel>;
    onVendorSelected: ActionOn<EventStepperMenu, StoreModel>;
    onSetEventType: ActionOn<EventStepperMenu, StoreModel>;
    onDetailsChanges: ActionOn<EventStepperMenu, StoreModel>;
    onAddCatgory: ActionOn<EventStepperMenu, StoreModel>;
}

interface EventStepperItem {
    id: number;
    isActive: boolean;
    title: string;
    isComplete: boolean;
    baseUrl: string;
    vendors?: EventSubStepItem[];
}

interface EventSubStepItem {
    id: string;
    vendor: Vendor;
}

export const stepperMenu: EventStepperMenu = {
    detailsNameIsSet: false,
    detailsDateIsSet: false,
    detailsNumberOfGuestIsSet: false,
    detailsLocationsIsSet: false,
    items: [
        {
            id: 1,
            title: 'Start',
            isActive: false,
            isComplete: false,
            baseUrl: '/event',
        },
        {
            id: 2,
            title: 'Details',
            isActive: false,
            isComplete: false,
            baseUrl: '/event/new/details',
        },
        {
            id: 3,
            title: 'Category',
            isActive: false,
            isComplete: false,
            baseUrl: '/event/new/category',
        },
    ],

    toggleActiveItem: action((state, payload) => {
        state.items = state.items.map((menuItem) => {
            const item = { ...menuItem };
            if (item.baseUrl === payload) item.isActive = true;
            else item.isActive = false;

            return item;
        });
    }),
    onVendorSelected: actionOn(
        (_, storeActions) => storeActions.event.setSelectedVendor,
        (state, payload) => {
            const vendor = payload.payload;
            const idx = state.items.findIndex(
                (x) => x.title.toLocaleLowerCase() === vendor.category
            );

            if (idx === -1) return;

            const itemToUpdate = state.items[idx];

            const subItems = itemToUpdate.vendors ?? [];

            const vendorIdx = subItems.findIndex(
                (x) => x.id === vendor.vendor.id
            );

            if (vendorIdx === -1)
                subItems.push({
                    id: vendor.vendor.id,
                    vendor: vendor.vendor,
                });
            else {
                subItems.splice(vendorIdx, 1);
            }

            itemToUpdate.vendors = subItems;
            state.items[idx] = itemToUpdate;
        }
    ),
    onCategoryChanged: actionOn(
        (_, storeActions) => storeActions.event.addCatgoryType,
        (state, target) => {
            const updatedCategory = target.payload.toLocaleLowerCase();

            const idx = state.items.findIndex(
                (x) => x.title.toLocaleLowerCase() === updatedCategory
            );
            if (idx === -1) {
                state.items.push({
                    id: state.items.length + 1,
                    title:
                        updatedCategory.charAt(0).toLocaleUpperCase() +
                        updatedCategory.slice(1),
                    isActive: false,
                    isComplete: false,
                    baseUrl: `/event/new/vendor/${updatedCategory}`,
                });
            } else {
                state.items.splice(idx, 1);
            }
        }
    ),
    onSetEventType: actionOn(
        (_, storeActions) => storeActions.event.setEventType,
        (state) => {
            state.items[0].isComplete = true;
        }
    ),
    onDetailsChanges: actionOn(
        (_, storeActions) => [
            storeActions.event.setTitle,
            storeActions.event.setEventDate,
            storeActions.event.setNumberOfGuest,
            /* storeActions.event.addLocation, */
        ],
        (state, target) => {
            const [setTitle, setEventDate, setNumberOfGuest] = // , addLocation] =
                target.resolvedTargets;

            switch (target.type) {
                case setTitle:
                    state.detailsNameIsSet = !!target.payload;
                    break;
                case setEventDate:
                    state.detailsDateIsSet = !!target.payload;
                    break;
                case setNumberOfGuest:
                    state.detailsNumberOfGuestIsSet = target.payload > 0;
                    break;
                // case addLocation:
                //     state.detailsLocationsIsSet = true;
                //     break;
                default:
                    break;
            }

            if (
                state.detailsNameIsSet &&
                state.detailsDateIsSet &&
                state.detailsNumberOfGuestIsSet &&
                state.detailsLocationsIsSet
            ) {
                state.items[1].isComplete = true;
            }
        }
    ),
    onAddCatgory: actionOn(
        (_, storeActions) => storeActions.event.addCatgoryType,
        (state) => {
            state.items[2].isComplete = true;
        }
    ),
};
