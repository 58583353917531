import { APP_CONFIG } from '../../appConfig';
import {
    ImageDescriptor,
    SupplierInfo,
} from '../../store/models/profile/profile';
import { IUser } from '../../store/models/user/user';

export const UploadLogo = async (
    supplierId: string,
    image: File,
    token: string
): Promise<string> => {
    const data = new FormData();
    data.append('file', image);

    const response = await fetch(
        `${APP_CONFIG.baseApiUrl}/supplier/logo/${supplierId}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'POST',
            body: data,
        }
    );
    const result = await response.json();

    return result.path;
};

export const UploadImage = async (
    supplierId: string,
    image: File,
    token: string
): Promise<ImageDescriptor> => {
    const data = new FormData();
    data.append('file', image);

    const response = await fetch(
        `${APP_CONFIG.baseApiUrl}/supplier/image/${supplierId}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'POST',
            body: data,
        }
    );
    const result = await response.json();

    return result as ImageDescriptor;
};

export const DeleteCompanyImage = async (
    imageId: string,
    token: string
): Promise<void> => {
    await fetch(`${APP_CONFIG.baseApiUrl}/supplier/image/${imageId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
    });
};

export const SaveSupplier = async (
    payload: SupplierInfo,
    token: string
): Promise<SupplierInfo> => {
    const data =
        payload.id === undefined
            ? {
                  method: 'POST',
                  url: `${APP_CONFIG.baseApiUrl}/supplier`,
              }
            : {
                  method: 'PUT',
                  url: `${APP_CONFIG.baseApiUrl}/supplier/${payload.id}`,
              };

    const response = await fetch(data.url, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        method: data.method,
        body: JSON.stringify(payload),
    });
    // TODO: Create map function to get it typesafe
    return response.json();
};

// const sleep = (ms: number): Promise<void> =>
//     new Promise((resolve) => setTimeout(resolve, ms));

export const SaveUserProfile = async (
    payload: IUser,
    token: string
): Promise<void> => {
    // console.log(JSON.stringify(payload));

    await fetch(`${APP_CONFIG.baseApiUrl}/users`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(payload),
    });

    // return response.json();
};
