import { actionOn, ActionOn, persist } from 'easy-peasy';

import eventModel, { EventModel } from './events/event';
import externalData, { ExternalData } from './externalData/externalData';
import notification, { NotificationModel } from './notification/notification';
import profileModel, { ProfileModel } from './profile/profile';
import registerModel, { RegisterModel } from './register/register';
import userModel, { UserModel } from './user/user';

export interface StoreModel {
    event: EventModel;
    externalData: ExternalData;
    notifications: NotificationModel;
    register: RegisterModel;
    profile: ProfileModel;
    user: UserModel;
    isLoading: boolean;

    onLoadingListener: ActionOn<StoreModel, StoreModel>;
}

const model: StoreModel = {
    event: persist(eventModel),
    externalData: persist(externalData),
    notifications: persist(notification),
    register: persist(registerModel),
    profile: persist(profileModel),
    user: userModel,
    isLoading: false,

    onLoadingListener: actionOn(
        (storeActions) => [storeActions.profile.setIsLoading],
        (state, target) => {
            const [setIsLoading] = target.resolvedTargets;
            switch (target.type) {
                case setIsLoading:
                    state.isLoading = target.payload;
                    break;
                default:
                    state.isLoading = false;
            }
        }
    ),
};

export default model;
