import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType } from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface ProtectedRouteProps extends RouteProps {
    component: ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    component,
    ...args
}) => (
    <Route
        component={withAuthenticationRequired(component, {
            onRedirecting: () => <>LOADING</>,
        })}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...args}
    />
);

export default ProtectedRoute;
