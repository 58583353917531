import {
    action,
    Action,
    actionOn,
    ActionOn,
    computed,
    Computed,
    thunk,
    Thunk,
    thunkOn,
    ThunkOn,
} from 'easy-peasy';
import _, { debounce } from 'lodash';

import { StoreModel } from '..';

import { getAreas } from '../../../components/county/get-areas';
import { Area } from '../../../components/county/types';
import { AddImage } from '../../../components/service/remote-data/add-Image';
import { DeleteImage } from '../../../components/service/remote-data/delete-image';

import { getEntities } from '../../../components/service/remote-data/get-entites';
import { UpdateService } from '../../../components/service/remote-data/update-service';
import { Service } from '../../../components/service/utils/types';
import { Entity } from '../../../infrastructure/types/entities';
import { Category } from '../events/constants';
import { ImageDescriptor, UploadImagePayload } from '../profile/profile';

interface Step {
    id: number;
    labelKey: string;
    completed: boolean;
}

interface UpdateStepCompletedPayload {
    id: number;
    updateValue: boolean;
}

interface UpdateHoursPayload {
    day:
        | 'Monday'
        | 'Tuesday'
        | 'Wednesday'
        | 'Thursday'
        | 'Friday'
        | 'Saturday'
        | 'Sunday';
    from?: string;
    to?: string;
}

export interface SelectableCategory {
    entity: Entity;
    checked: boolean;
}

export interface EditServiceModel {
    areas: Area[];
    currentCategory?: Category;
    entities: Entity[];
    filteredCategories: SelectableCategory[];
    hasChanges: boolean;
    steps: Step[];
    service?: Service;

    isInEditMode: Computed<EditServiceModel, boolean>;
    activeCategories: Computed<EditServiceModel, string[]>;
    selectedAreas: Computed<EditServiceModel, Area[]>;

    setHasChanges: Action<EditServiceModel>;
    setEditService: Action<EditServiceModel, Service>;
    endEdit: Action<EditServiceModel>;
    setOpenHours: Action<EditServiceModel, UpdateHoursPayload>;
    updateStepCompleted: Action<EditServiceModel, UpdateStepCompletedPayload>;
    setAreas: Action<EditServiceModel, Area[]>;
    selectEntity: Action<EditServiceModel, Entity>;
    setEntities: Action<EditServiceModel, Entity[]>;
    setCurrentCategory: Action<EditServiceModel, Category>;
    setTitle: Action<EditServiceModel, string>;
    setDescription: Action<EditServiceModel, string>;
    setMinNumberOfGuest: Action<EditServiceModel, string>;
    setMaxNumberOfGuest: Action<EditServiceModel, string>;
    setMinPrice: Action<EditServiceModel, string>;
    setMaxPrice: Action<EditServiceModel, string>;
    setSelectedAreas: Action<EditServiceModel, Area[]>;
    addImage: Action<EditServiceModel, ImageDescriptor>;
    removeImage: Action<EditServiceModel, string>;

    uploadImage: Thunk<
        EditServiceModel,
        UploadImagePayload,
        unknown,
        StoreModel
    >;
    deleteImage: Thunk<EditServiceModel, ImageDescriptor, unknown, StoreModel>;

    loadEnitites: Thunk<EditServiceModel>;
    loadAreas: Thunk<EditServiceModel>;
    onChange: ThunkOn<EditServiceModel, unknown, StoreModel>;

    onCurrentCategoryChanged: ActionOn<EditServiceModel>;

    onCalculateCompleteSteps: ThunkOn<EditServiceModel>;
}

const debounceUpdateService = debounce(
    async (payload: Service, token: string) => {
        await UpdateService(payload, token);
    },
    2000
);

export const editServiceModel: EditServiceModel = {
    areas: [],
    steps: [
        {
            id: 1,
            labelKey: 'serviceType',
            completed: false,
        },
        { id: 2, labelKey: 'information', completed: false },
        { id: 3, labelKey: 'openingHours', completed: false },
        { id: 4, labelKey: 'images', completed: false },
    ],
    entities: [],
    filteredCategories: [],
    hasChanges: false,

    // Computed
    isInEditMode: computed((state) => !!state.service),

    activeCategories: computed((state) => {
        const selectedEntities = state.service?.entityIds || [];
        const ac = state.entities.filter(({ code }) =>
            selectedEntities.some((x) => x === code)
        );
        return _.uniqBy(ac, 'category').map((x) => x.category);
    }),
    selectedAreas: computed((state) => {
        if (state.areas.length === 0) return [];

        const locations = state.service?.locations || [];

        return state.areas.filter((a) => locations.some((l) => l === a.id));
    }),

    // Actions
    addImage: action((state, payload) => {
        if (!state.service) return;
        state.service.images = [payload].concat(state.service.images || []);
    }),
    removeImage: action((state, payload) => {
        if (!state.service) return;

        const idx = state.service.images.findIndex((x) => x.id === payload);

        if (idx > -1) state.service.images.splice(idx, 1);
    }),
    setHasChanges: action((state) => {
        state.hasChanges = true;
    }),
    setEditService: action((state, payload) => {
        state.service = payload;
    }),
    endEdit: action((state) => {
        state.service = undefined;
        state.selectedAreas = [];
        state.steps = state.steps.map((step) => {
            return { ...step, completed: false };
        });
        state.currentCategory = undefined;
        state.filteredCategories = [];
        state.hasChanges = false;
    }),
    updateStepCompleted: action((state, payload) => {
        const elementIdx = state.steps.findIndex((e) => e.id === payload.id);

        const newArray = [...state.steps];
        newArray[elementIdx] = {
            ...newArray[elementIdx],
            completed: payload.updateValue,
        };

        state.steps = newArray;
    }),
    setAreas: action((state, payload) => {
        state.areas = payload;
    }),
    selectEntity: action((state, payload) => {
        if (!state.service) return;

        if (!state.service.entityIds) state.service.entityIds = [payload.code];
        else {
            const idx = (state.service.entityIds || []).findIndex(
                (o) => o === payload.code
            );
            if (idx > -1) state.service.entityIds.splice(idx, 1);
            else state.service.entityIds.push(payload.code);
        }

        const elementIdx = state.filteredCategories.findIndex(
            (e) => e.entity.code === payload.code
        );

        const newArray = [...state.filteredCategories];

        newArray[elementIdx] = {
            ...newArray[elementIdx],
            checked: !newArray[elementIdx].checked,
        };

        state.filteredCategories = newArray;
    }),
    setEntities: action((state, payload) => {
        state.entities = payload;
    }),
    setCurrentCategory: action((state, payload) => {
        if (state.currentCategory !== payload) {
            state.currentCategory = payload;
        } else {
            state.currentCategory = undefined;
        }
    }),
    setTitle: action((state, payload) => {
        if (state.service) state.service.title = payload;
    }),
    setDescription: action((state, payload) => {
        if (state.service) state.service.description = payload;
    }),
    setMinNumberOfGuest: action((state, payload) => {
        if (!state.service) return;
        if (payload.length === 0) state.service.minNumberOfGuest = undefined;

        const value = Number(payload);
        if (!Number.isNaN(value)) state.service.minNumberOfGuest = value;
    }),
    setMaxNumberOfGuest: action((state, payload) => {
        if (!state.service) return;
        if (payload.length === 0) state.service.maxNumberOfGuest = undefined;

        const value = Number(payload);
        if (!Number.isNaN(value)) state.service.maxNumberOfGuest = value;
    }),
    setMinPrice: action((state, payload) => {
        if (!state.service) return;
        if (payload.length === 0) state.service.minPrice = undefined;

        const value = Number(payload);
        if (!Number.isNaN(value)) state.service.minPrice = value;
    }),
    setMaxPrice: action((state, payload) => {
        if (!state.service) return;
        if (payload.length === 0) state.service.maxPrice = undefined;

        const value = Number(payload);
        if (!Number.isNaN(value)) state.service.maxPrice = value;
    }),
    setSelectedAreas: action((state, payload) => {
        if (state.service) state.service.locations = payload.map((a) => a.id);
        // const idx = state.service.locations.findIndex((o) => o === payload.id);
        // if (idx > -1) state.service.entityIds.splice(idx, 1);
        // else state.service.entityIds.push(payload.code);
    }),
    setOpenHours: action((state, payload) => {
        if (!state.service) return;

        switch (payload.day) {
            case 'Monday': {
                state.service.hours.mon =
                    payload.from && payload.to
                        ? {
                              from: payload.from,
                              to: payload.to,
                          }
                        : undefined;

                break;
            }
            case 'Tuesday': {
                state.service.hours.tue =
                    payload.from && payload.to
                        ? {
                              from: payload.from,
                              to: payload.to,
                          }
                        : undefined;

                break;
            }
            case 'Wednesday': {
                state.service.hours.wed =
                    payload.from && payload.to
                        ? {
                              from: payload.from,
                              to: payload.to,
                          }
                        : undefined;

                break;
            }
            case 'Thursday': {
                state.service.hours.thu =
                    payload.from && payload.to
                        ? {
                              from: payload.from,
                              to: payload.to,
                          }
                        : undefined;

                break;
            }
            case 'Friday': {
                state.service.hours.fri =
                    payload.from && payload.to
                        ? {
                              from: payload.from,
                              to: payload.to,
                          }
                        : undefined;

                break;
            }
            case 'Saturday': {
                state.service.hours.sat =
                    payload.from && payload.to
                        ? {
                              from: payload.from,
                              to: payload.to,
                          }
                        : undefined;

                break;
            }
            case 'Sunday': {
                state.service.hours.sun =
                    payload.from && payload.to
                        ? {
                              from: payload.from,
                              to: payload.to,
                          }
                        : undefined;

                break;
            }
            default: {
                // eslint-disable-next-line no-console
                console.error('Should not end up here');
            }
        }
    }),
    // Thunks
    loadEnitites: thunk(async (actions) => {
        const entites = await getEntities();
        actions.setEntities(entites);
    }),
    loadAreas: thunk(async (actions, payload, { getState }) => {
        const { areas } = getState();
        if (areas.length > 0) return;

        const result = await getAreas();

        actions.setAreas(result);
    }),
    uploadImage: thunk(
        async (actions, payload, { getState, getStoreState }) => {
            try {
                const { service } = getState();
                if (!service) return;

                const {
                    profile: { token },
                } = getStoreState();

                const imageResponse = await AddImage(
                    service.offeringId,
                    payload.file,
                    token
                );
                actions.addImage(imageResponse);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            } finally {
                // actions.setIsLoading(false);
            }
        }
    ),
    deleteImage: thunk(
        async (actions, payload, { getState, getStoreState }) => {
            try {
                const { service } = getState();
                if (!service) return;

                const {
                    profile: { token },
                } = getStoreState();

                await DeleteImage(service.offeringId, payload.id, token);
                actions.removeImage(payload.id);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            } finally {
                // actions.setIsLoading(false);
            }
        }
    ),

    // ActionOn
    onCurrentCategoryChanged: actionOn(
        (actions) => actions.setCurrentCategory,
        (state, value) => {
            const updatedCategory = value.payload;
            if (!updatedCategory) {
                state.filteredCategories = [];
                return;
            }

            state.filteredCategories = state.entities
                .filter((x) => x.category === updatedCategory)
                .map((x) => {
                    return {
                        entity: x,
                        checked: (state.service?.entityIds || []).some(
                            (z) => z === x.code
                        ),
                    };
                });
        }
    ),

    // Thunk on
    onChange: thunkOn(
        (actions) => [
            actions.setTitle,
            actions.setDescription,
            actions.setMinNumberOfGuest,
            actions.setMaxNumberOfGuest,
            actions.setMinPrice,
            actions.setMaxPrice,
            actions.selectEntity,
            actions.setSelectedAreas,
            actions.setOpenHours,
        ],
        async (actions, target, { getState, getStoreState }) => {
            const { service } = getState();
            const {
                profile: { token },
            } = getStoreState();

            if (service) debounceUpdateService(service, token);

            actions.setHasChanges();
        }
    ),

    onCalculateCompleteSteps: thunkOn(
        (actions) => [
            actions.selectEntity,
            actions.setTitle,
            actions.setEditService,
            actions.setOpenHours,
            actions.addImage,
            actions.removeImage,
        ],
        (actions, target, { getState }) => {
            const [
                selectEntity,
                setTitle,
                setEditService,
                setOpenHours,
                addImage,
                deleteImage,
            ] = target.resolvedTargets;
            const { service } = getState();
            switch (target.type) {
                case setEditService: {
                    actions.updateStepCompleted({
                        id: 1,
                        updateValue: (service?.entityIds || []).length > 0,
                    });
                    actions.updateStepCompleted({
                        id: 2,
                        updateValue: (service?.title || '').length > 0,
                    });
                    actions.updateStepCompleted({
                        id: 3,
                        updateValue:
                            service?.hours.mon !== undefined ||
                            service?.hours.tue !== undefined ||
                            service?.hours.wed !== undefined ||
                            service?.hours.thu !== undefined ||
                            service?.hours.fri !== undefined ||
                            service?.hours.sat !== undefined ||
                            service?.hours.sun !== undefined,
                    });
                    actions.updateStepCompleted({
                        id: 4,
                        updateValue: (service?.images || []).length > 0,
                    });
                    break;
                }
                case selectEntity: {
                    actions.updateStepCompleted({
                        id: 1,
                        updateValue: (service?.entityIds || []).length > 0,
                    });
                    break;
                }
                case setTitle: {
                    actions.updateStepCompleted({
                        id: 2,
                        updateValue: (service?.title || '').length > 0,
                    });
                    break;
                }
                case setOpenHours: {
                    actions.updateStepCompleted({
                        id: 3,
                        updateValue:
                            service?.hours.mon !== undefined ||
                            service?.hours.tue !== undefined ||
                            service?.hours.wed !== undefined ||
                            service?.hours.thu !== undefined ||
                            service?.hours.fri !== undefined ||
                            service?.hours.sat !== undefined ||
                            service?.hours.sun !== undefined,
                    });
                    break;
                }
                case addImage:
                case deleteImage: {
                    actions.updateStepCompleted({
                        id: 4,
                        updateValue: (service?.images || []).length > 0,
                    });
                    break;
                }
                default: {
                    // eslint-disable-next-line no-console
                    console.error('Unsupported listener');
                }
            }
        }
    ),
};

export default editServiceModel;
