import { APP_CONFIG } from '../appConfig';

import { Category } from '../store/models/events/constants';
import { Vendor } from '../store/models/events/event';
import { Supplier } from '../store/models/externalData/interfaces';
import { ExternalCompanyInfo } from '../store/models/profile/profile';
import { Location as ApiLocation } from './models/Location';
// import { ErrorResponse, ListLocations200Response } from './responses';

// export interface MyhattLibrary {
//     '/locationproxy/muni': {
//         get: () => Promise<ListLocations200Response | ErrorResponse>;
//     };
// }

// type PathParamenter<TPath extends string> =
//     TPath extends `${infer Head}/{${infer Parameter}}${infer Tail}`
//         ? [pathParameter: string, ...parms: PathParamenter<Tail>]
//         : [];

// export type Path = <TPath extends keyof MyhattLibrary>(
//     path: TPath,
//     ...pathParams: PathParamenter<TPath>
// ) => MyhattLibrary[TPath];

// export declare function MyhattClient(baseApiUrl: string): {
//     path: Path;
// };

// const client = MyhattClient(APP_CONFIG.baseApiUrl)
//     .path('/locationproxy/muni')
//     .get();

export const GetMuncipials = async (): Promise<ApiLocation[]> => {
    const response = await fetch(`${APP_CONFIG.baseApiUrl}/locationproxy`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data: ApiLocation[] = await response.json();

    return data.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
    });
};

export const GetAllSuppliers = async (): Promise<Supplier[]> => {
    const response = await fetch(`${APP_CONFIG.baseApiUrl}/supplier/all`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data: Supplier[] = await response.json();

    return data.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
    });
};

export const GetEventCategories = async (): Promise<Category[]> => {
    const response = await fetch(`${APP_CONFIG.baseApiUrl}/entity/area`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response.json();
};

export const GetVendors = async (categories: Category[]): Promise<Vendor[]> => {
    const queryParams: string = categories
        .map((c) => `categories=${c}`)
        .join('&');

    const response = await fetch(
        `${APP_CONFIG.baseApiUrl}/event/suppliers?${queryParams}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return response.json();
};

export const GetCompanyInfoByOrgnumber = async (
    orgNumber: number
): Promise<ExternalCompanyInfo> => {
    const response = await fetch(
        `${APP_CONFIG.baseApiUrl}/brregproxy/lookup?orgNumber=${orgNumber}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    if (!response.ok) {
        const err = new Error('Not 2xx repsonse');
        throw err;
    } else return response.json();
};

// export const GetSupplierInfo = async (token: string): Promise<SupplierInfo> => {
//     const response = await fetch(`${APP_CONFIG.baseApiUrl}/supplier`, {
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//         },
//     });
//     if (response.ok) return response.json();

//     if (response.status === 404) return {} as SupplierInfo;

//     const err = new Error('Not 2xx repsonse');
//     throw err;
//     // else return response.json();
// };

// type SupplierDTO = Omit<SupplierInfo, 'useSameAddress'

// const toSupplierDTO = (source: SupplierInfo):
