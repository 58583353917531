import { createTheme, responsiveFontSizes, Theme } from '@mui/material';

// declare module '@mui/material/styles' {
//     interface Theme {
//         status: {
//             danger: React.CSSProperties['color'];
//         };
//     }

//     interface Palette {
//         myhattSelected: Palette['primary'];
//     }
//     interface PaletteOptions {
//         myhattSelected: PaletteOptions['primary'];
//     }

//     interface PaletteColor {
//         darker?: string;
//     }
//     interface SimplePaletteColorOptions {
//         darker?: string;
//     }
//     // interface ThemeOptions {
//     //     status: {
//     //         danger: React.CSSProperties['color'];
//     //     };
//     // }
// }

export const theme: Theme = responsiveFontSizes(
    createTheme({
        // breakpoints: {
        //     values: {
        //         xs: 0,
        //         sm: 350,
        //         md: 650,
        //         lg: 900,
        //     },
        // },
        palette: {
            primary: {
                main: '#2E3D4A',
            },
            secondary: {
                main: '#FFFFFF',
                dark: '#E7E7E6',
            },
            text: {
                primary: '#FFFFFF',
                secondary: '#2E3D4A',
                disabled: 'grey',
            },
            action: {
                disabled: 'grey',
            },
        },
        typography: {
            fontFamily: ['nobel'].join(','),
        },
        components: {
            MuiContainer: {
                styleOverrides: {
                    root: {
                        height: '100%',
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        backgroundColor: '#2E3D4A',
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    variant: 'outlined',
                },
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'filled',
                    fullWidth: true,
                },
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                        // color: '#2E3D4A',
                        // '& .MuiInputBase-root': {
                        //     borderRadius: 0,
                        //     backgroundColor: '#FFFFFF',
                        //     color: '#2E3D4A',
                        //                 '& fieldset': {
                        //                     borderColor: '#FFFFFF',
                        //                 },
                        // '&:hover fieldset': {
                        //     borderColor: '#FFFFFF',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: '#FFFFFF',
                        // },
                        // },
                        '& .MuiFormHelperText-root': {
                            color: '#FFFFFF',
                        },
                        '& .MuiFilledInput-root': {
                            backgroundColor: '#FFFFFF',
                            color: '#2E3D4A',
                            '&:hover': {
                                backgroundColor: '#FFFFFF',
                            },
                            '&.Mui-focused': {
                                backgroundColor: '#E7E7E6',
                            },
                        },
                    },
                },
            },

            // ,
            // marginTop: '16px',
            // padding: 'px',
            //         borderColor: 'yellow',
            //         p: 2,
            //         color: 'success',
            // '& .MuiInput-underline:after': {
            //     borderBottomColor: 'green',
            // },
            // '& .MuiInputBase-root': {
            // border: '2px solid yellow',
            // maxHeight: '1.5rem',
            // borderRadius: 0,
            // backgroundColor: '#FFFFFF',
            // color: '#2E3D4A',
            // fontSize: '1.2rem',
            // '& .Input': {
            //     backgoundColor: 'yellow',
            // },

            // backgroundColor: 'yellow',
            // color: 'primary',
            //             borderColor: 'yellow',
            //     //             backgoundColor: 'yellow',

            // },
            // '& .MuiInputBase-root.Mui-focused': {
            //     backgroundColor: 'hotpink',
            // },
            // '& .MuiInputBase-root.Mui-focused': {
            //     backgroundColor: 'hotpink',
            // },
            //             '& .MuiInputLabel-root': {
            //                 color: '#FFFFFF',
            //                 fontSize: '1.25rem',
            //                 top: -16,
            //                 left: -14,
            //             },
            //         },
            //     },
            //     defaultProps: {
            //         variant: 'outlined',
            //         color: 'secondary',
            //         InputLabelProps: {
            //             shrink: true,
            //         },
            //         InputProps: {
            //             notched: false,
            //         },
            //         //     fullWidth: true,

            //         //     autoComplete: 'off',

            //         //     focused: true,
            //         //     autoFocus: true,
            //     },
            // },
        },
    })
);
