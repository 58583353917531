export enum EventType {
    Unknown = 'unknown',
    Banquet = 'banquet',
    Seminar = 'seminar',
    KickOff = 'kick-off',
    Vernissage = 'vernissage',
    Teambuilding = 'teambuilding',
    ChristmasParty = 'christmas-party',
    Baptism = 'baptism',
    Wedding = 'wedding',
    Birthday = 'birthday',
    Anniversity = 'anniversity',
    SummerParty = 'summer-party',
    Other = 'other',
}

export enum Category {
    FoodAndBeverage = 'food and beverage',
    Venue = 'venue',
    SpeakerLecturer = 'speaker/lecturer',
    Activity = 'activity',
    Entertainment = 'entertainment',
    Other = 'other',
}
